import React, { useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { closePrint } from "../../redux/actions/actions";

import { togglePrint } from "../../redux/actions/actions";

import PrintThemeCard from "../../components/print/PrintThemeCard";
import PrintTopicCard from "../../components/print/PrintTopicCard";
import PrintMethodCard from "../../components/print/PrintMethodCard";
import PrintGoalCard from "../print/printGoalCard";

import { Link } from "react-router-dom";
import vlaanderen from "../../assets/images/logo-vlaanderen-omgeving.svg";

const Print = (props) => {
  // Redux aanpassen mogelijk maken
  const dispatch = useDispatch();
  const { pinnedCards, pinnedGoals } = props;

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const print = () => window.print();

  return (
    <div className="klimax-print pdf_container">
      <div className="klimax-print-wrapper">
        <div className="container">
          <div className="klimax-print__buttons">
            <button
              className="klimax-button klimax-button--black"
              onClick={print}
            >
              <i className="icon-klimax-download" /> download
            </button>
            <Link
              to="/"
              className="klimax-button"
              onClick={() => dispatch(closePrint())}
            >
              <i className="icon-klimax-close"></i> sluit
            </Link>
          </div>

                    <div className="klimax-print__intro">
                        <h2>Download</h2>
                        <div class="klimax-print__legende">
                            <div>
                                <p>
                                    Wetenschappelijk onderzoek wijst uit dat klimaateducatie gebaat is bij een holistische en actiegerichte aanpak die ingebed zit in de leef- en leerwereld. Door 4 ingrediënten met elkaar te verbinden kan je komen tot impactvolle klimaateducatie. Welke ingrediënten heb jij verzameld? Zijn alle ingrediënten aanwezig? Wel ingrediënt kan je nog extra opnemen om de impact te verhogen? 
                                </p>
                                <ul>
                                    <li>
                                        Thema's uit de leefwereld{" "}
                                        <div className="klimax-print__count">
                                            <span className="klimax-print__count--theme">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                            el.sort === "theme"
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        Hot topics{" "}
                                        <div className="klimax-print__count">
                                            <span className="klimax-print__count--topic-oorzaken">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                            el.sort === "topic" &&
                                                            el.topicCatId === 1
                                                    ).length
                                                }
                                            </span>
                                            <span className="klimax-print__count--topic-gevolgen">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                        el.sort === "topic" &&
                                                        el.topicCatId === 2
                                                    ).length
                                                }
                                            </span>
                                            <span className="klimax-print__count--topic-oplossingen">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                        el.sort === "topic" &&
                                                        el.topicCatId === 3
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        Werkvormen{" "}
                                        <div className="klimax-print__count">
                                            <span className="klimax-print__count--method-yellow">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                            el.goalMethodCatId ===
                                                                1 ||
                                                            el.goalMethodCatId ===
                                                                2
                                                    ).length
                                                }
                                            </span>{" "}
                                            <span className="klimax-print__count--method-orange">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                            el.goalMethodCatId ===
                                                                3 ||
                                                            el.goalMethodCatId ===
                                                                4
                                                    ).length
                                                }
                                            </span>{" "}
                                            <span className="klimax-print__count--method-red">
                                                {
                                                    pinnedCards.filter(
                                                        (el) =>
                                                            el.goalMethodCatId ===
                                                                5 ||
                                                            el.goalMethodCatId ===
                                                                6
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        Competenties{" "}
                                        <div className="klimax-print__count">
                                            <span className="klimax-print__count--goal-yellow">
                                                {
                                                    pinnedGoals.filter(
                                                        (el) =>
                                                            el.id === 1 ||
                                                            el.id === 2
                                                    ).length
                                                }
                                            </span>{" "}
                                            <span className="klimax-print__count--goal-orange">
                                                {
                                                    pinnedGoals.filter(
                                                        (el) =>
                                                            el.id === 3 ||
                                                            el.id === 4
                                                    ).length
                                                }
                                            </span>{" "}
                                            <span className="klimax-print__count--goal-red">
                                                {
                                                    pinnedGoals.filter(
                                                        (el) =>
                                                            el.id === 5 ||
                                                            el.id === 6
                                                    ).length
                                                }
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

              <div className="klimax-print__svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="148"
                  height="148"
                  viewBox="0 0 148 148"
                >
                  <g
                    id="Group_291"
                    data-name="Group 291"
                    transform="translate(-646 -250)"
                  >
                    <circle
                      id="Ellipse_38"
                      data-name="Ellipse 38"
                      cx="74"
                      cy="74"
                      r="74"
                      transform="translate(646 250)"
                      fill="#fff"
                    />
                    <g
                      id="Group_290"
                      data-name="Group 290"
                      transform="translate(-20329.486 11023.514)"
                    >
                      <path
                        id="werkvorm_communicatie_rood"
                        data-name="werkvorm_communicatie_rood"
                        d="M87.681,64.679a28.285,28.285,0,0,1-49.305.3L22.817,74.042a46.279,46.279,0,0,0,80.327-.131Z"
                        transform="translate(20986.543 -10750.452)"
                        fill="#f2441a"
                        fillOpacity={
                          pinnedCards.some(
                            (el) =>
                              el.goalMethodCatId === 5 ||
                              el.goalMethodCatId === 6
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="werkvorm_kritisch-denken_geel"
                        data-name="werkvorm_communicatie_rood"
                        d="M36.015,64.324A28.341,28.341,0,0,1,64.324,36.015V18A46.289,46.289,0,0,0,24.2,87.428l15.56-9.064a28.119,28.119,0,0,1-3.743-14.04"
                        transform="translate(20985.162 -10763.838)"
                        fill="#f4cc27"
                        fillOpacity={
                          pinnedCards.some(
                            (el) =>
                              el.goalMethodCatId === 1 ||
                              el.goalMethodCatId === 2
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="werkvorm_actiegericht_oranje"
                        data-name="Path 1578"
                        d="M82.309,64.324a28.122,28.122,0,0,1-3.571,13.74L94.2,87.3A46.289,46.289,0,0,0,54,18V36.015A28.341,28.341,0,0,1,82.309,64.324"
                        transform="translate(20995.486 -10763.838)"
                        fill="#f78e34"
                        fillOpacity={
                          pinnedCards.some(
                            (el) =>
                              el.goalMethodCatId === 3 ||
                              el.goalMethodCatId === 4
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="doel_kritisch-denken_geel"
                        data-name="Path 1583"
                        d="M59.162,36A23.133,23.133,0,0,0,39.119,70.734L59.162,59.162Z"
                        transform="translate(20990.324 -10758.676)"
                        fill="#f4cc27"
                        fillOpacity={
                          pinnedGoals.some((el) => el.primaryColor === "yellow")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="doel_communicatie_rood"
                        data-name="Path 1584"
                        d="M38.424,65.572a23.125,23.125,0,0,0,40.086,0L58.467,54Z"
                        transform="translate(20991.02 -10753.514)"
                        fill="#f2441a"
                        fillOpacity={
                          pinnedGoals.some((el) => el.primaryColor === "red")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="doel_actiegericht_oranje"
                        data-name="Path 1585"
                        d="M54,36V59.162L74.043,70.734A23.133,23.133,0,0,0,54,36"
                        transform="translate(20995.486 -10758.676)"
                        fill="#f78e34"
                        fillOpacity={
                          pinnedGoals.some((el) => el.primaryColor === "orange")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="theme1"
                        data-name="Path 1586"
                        class="pinned"
                        d="M19.3,54H0a69.512,69.512,0,0,0,34.964,60.265L44.61,97.557A50.2,50.2,0,0,1,19.3,54"
                        transform="translate(20980 -10753.514)"
                        fill="#3e76da"
                        fillOpacity={
                          pinnedCards.some((el) => el.sort === "theme")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="theme2"
                        data-name="Path 1579"
                        d="M117.727,54h-19.3A50.2,50.2,0,0,1,74.083,97l9.646,16.707a69.512,69.512,0,0,0,34-59.709"
                        transform="translate(21001.246 -10753.514)"
                        fill="#3e76da"
                        fillOpacity={
                          pinnedCards.some((el) => el.sort === "theme")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="theme3"
                        data-name="Path 1587"
                        d="M61.694,94.6a49.856,49.856,0,0,1-24.876-6.627l-9.646,16.707a69.264,69.264,0,0,0,70.01-.556L87.536,87.418A49.875,49.875,0,0,1,61.694,94.6"
                        transform="translate(20987.793 -10743.931)"
                        fill="#3e76da"
                        fillOpacity={
                          pinnedCards.some((el) => el.sort === "theme")
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="topic_oplossing"
                        data-name="Path 1580"
                        d="M98.64,67.431h19.3A69.512,69.512,0,0,0,82.978,7.166L73.332,23.873A50.2,50.2,0,0,1,98.64,67.431"
                        transform="translate(21001.029 -10766.945)"
                        fill="#66a357"
                        fillOpacity={
                          pinnedCards.some(
                            (el) => el.sort === "topic" && el.topicCatId === 3
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="topic_oorzaak"
                        data-name="Path 1588"
                        d="M0,67.307H19.3a50.2,50.2,0,0,1,24.342-43L34,7.6A69.512,69.512,0,0,0,0,67.307"
                        transform="translate(20980 -10766.821)"
                        fill="#287b14"
                        fillOpacity={
                          pinnedCards.some(
                            (el) => el.sort === "topic" && el.topicCatId === 1
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                      <path
                        id="topic_gevolg"
                        data-name="Path 1589"
                        d="M61.909,19.3a49.856,49.856,0,0,1,24.876,6.627L96.431,9.221a69.264,69.264,0,0,0-70.01.556l9.646,16.707A49.875,49.875,0,0,1,61.909,19.3"
                        transform="translate(20987.576 -10769)"
                        fill="#4f8e40"
                        fillOpacity={
                          pinnedCards.some(
                            (el) => el.sort === "topic" && el.topicCatId === 2
                          )
                            ? "1"
                            : "0.2"
                        }
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div className="divider-line"></div>

          <div
            className="klimax-print-block klimax-print-block--goal"
            style={{
              display: pinnedGoals.some((el) => el.id) ? "block" : "none",
            }}
          >
            <h3>Competenties</h3>

            {pinnedGoals.length > 0 && <PrintGoalCard></PrintGoalCard>}
          </div>

                    <div
                        className="klimax-print-block klimax-print-block--theme"
                        style={{
                            display: pinnedCards.some(
                                (el) => el.sort === "theme"
                            )
                                ? "block"
                                : "none",
                        }}
                    >
                        <h3>Thema's uit de leefwereld</h3>

            {pinnedCards
              .filter((el) => el.sort === "theme")
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((card, i) =>
                card.sort === "theme" ? (
                  <PrintThemeCard card={card} key={i}></PrintThemeCard>
                ) : (
                  <div key={i} style={{ display: "none" }}></div>
                )
              )}
          </div>

          <div
            className="klimax-print-block klimax-print-block--topic"
            style={{
              display: pinnedCards.some((el) => el.sort === "topic")
                ? "block"
                : "none",
            }}
          >
            <h3>Hot topics rond de klimaatcrisis</h3>

            {pinnedCards
              .filter((el) => el.sort === "topic")
              .sort((a, b) => (a.title > b.title ? -1 : 1))
              .sort((a, b) => (a.topicCatId > b.topicCatId ? 1 : -1))
              .map((card, i) =>
                card.sort === "topic" ? (
                  <PrintTopicCard card={card} key={i}></PrintTopicCard>
                ) : (
                  <div key={i} style={{ display: "none" }}></div>
                )
              )}
          </div>

          <div
            className="klimax-print-block klimax-print-block--method"
            style={{
              display: pinnedCards.some((el) => el.sort === "method")
                ? "block"
                : "none",
            }}
          >
            <h3>Werkvormen</h3>

            {pinnedCards.map((card, i) =>
              card.sort === "method" ? (
                <PrintMethodCard card={card} key={i}></PrintMethodCard>
              ) : (
                <div key={i} style={{ display: "none" }}></div>
              )
            )}
          </div>

                    <p>Veel succes met jullie klimaatles of -project!</p>

          <div className="klimax-print__footer" aria-label="footer">
            <a
              href="https://www.vlaanderen.be/"
              target="_blank"
              aria-label="link naar website Vlaanderen"
            >
              <img src={vlaanderen} />
            </a>
            <p>Dit is mijn inspiratie van {date}.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pinnedCards: state.pinnedCards,
  pinnedGoals: state.pinnedGoals,
  pinnedGoalsFlipstate: state.pinnedGoalsFlipstate,
  highContrast: state.highContrast,
});
const mapDispatchToProps = (dispatch) => {
  return {
    togglePrint: () => dispatch(togglePrint()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Print);
