import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Toegankelijkheid = (props) => {
  return (
    <div className="klimax-box">
      <div className="klimax-box-wrapper">
        <Link to="/" className="klimax-button klimax-button--inline">
          <i className="icon-klimax-grid"></i> terug naar Klimax
        </Link>
        <div className="container">
          <h1 id="cookies">Toegankelijkheid</h1>
          <h3>Versie 1.0.0 - 20 juli 2021</h3>
         
          <p>
            De Vlaamse overheid streeft ernaar haar websites en mobiele applicaties toegankelijk
            te maken, overeenkomstig het <a href="http://www.ejustice.just.fgov.be/cgi_loi/loi_a1.pl?language=nl&amp;cn=2018120705&amp;table_name=wet&amp;caller=list&amp;fromtab=wet#LNK0011" target="_blank">bestuursdecreet van 7 december 2018</a> waarmee de <a is="vl-link" href="https://eur-lex.europa.eu/legal-content/NL/TXT/?uri=uriserv:OJ.L_.2016.327.01.0001.01.NLD&amp;toc=OJ:L:2016:327:TOC" target="_blank">Europese Richtlijn 2016/2102</a> is omgezet.
        </p>

          <p>
            Deze toegankelijkheidsverklaring is van toepassing op "deze applicatie".
          </p>

          <h2>Nalevingsstatus</h2>

          <p>
            Deze website voldoet niet aan de <a href="https://www.w3.org/TR/WCAG21" target="_blank" class="vl-link--inline vl-link--data-vl-inline vl-link"> Web Content Accessibility Guidelines versie 2.1 niveau AA</a>.
          </p>

          <h2>Niet-toegankelijke inhoud</h2>

          <p>
            De onderstaande inhoud is niet-toegankelijk om de volgende redenen:
          </p>

          <h3>Niet-naleving van het bestuursdecreet</h3>

          <table class="table table-bordered">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Omschrijving</th>
                    <th scope="col">WCAG criterium</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Tekst scoort niet overval voldoende op contrast.</td>
                    <td>1.4.3 Contrast (minimum)</td>
                </tr>
                <tr>
                    <td>Er is verlies van inhoud bij vergroting tot 400%.</td>
                    <td>1.4.10 Reflow</td>
                </tr>
                <tr>
                    <td>Tekst wordt afgesneden bij vergroting van de tekstafstand.</td>
                    <td>1.4.12 Tekstafstand</td>
                </tr>
                <tr>
                    <td>Meldingen verdwijnen automatisch na enkele seconden.</td>
                    <td>2.2.1 Timing aanpasbaar</td>
                </tr>
                <tr>
                    <td>Er is geen skiplink om blokken met herhalende inhoud zoals de navigatie over te slaan met het toetsenbor</td>
                    <td>2.4.1 Blokken omzeilen</td>
                </tr>
                <tr>
                    <td>De focus met het toetsenbord of een screenreader wordt niet correct gestuurd bij interne links en dialoogvensters.</td>
                    <td>2.4.3 Focus volgorde</td>
                </tr>
                <tr>
                    <td>De focus van sommige elementen is niet zichtbaar omwille van de cookiebanner.</td>
                    <td>2.4.7 Focus zichtbaar</td>
                </tr>
                <tr>
                    <td>Het zichtbaar label van sommige elementen komt niet overeen met de naam van het element in de code.</td>
                    <td>2.5.3 Label in naam</td>
                </tr>
                <tr>
                    <td>De website bevat HTML-validatiefouten met betrekking tot toegankelijkheid.</td>
                    <td>4.1.1 Parsen</td>
                </tr>
            </tbody>
            </table>

        <h2>Opstelling van deze toegankelijkheidsverklaring</h2>
          <p>
            Deze toegankelijkheidsverklaring is opgesteld op 20 juli 2021 en werd voor het laatst herzien op 20 juli 2021.
          </p>

          <h2>Feedback en contactgegevens</h2>

          <p>Ondervind u problemen en wenst u hulp bij het vinden van informatie of het uitvoeren van een actie? Hebt u een vraag of opmerking over de toegankelijkheid van deze website of toepassing, of over deze toegankelijkheidsverklaring? Neem contact op met Departement Omgeving.</p>

          <div id="contact-card-1">
            <div slot="info" data-vl-type="contact">
                <h3 slot="title">Departement Omgeving</h3>
            </div>
            <div slot="properties" class="vl-properties">
                <div is="vl-properties-list" class="vl-properties__list">
                    <div is="vl-property-term" class="vl-properties__label">Adres</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        Koning Albert II-laan 20 bus 8<br></br>1000 Brussel<br></br>België
                    </div>
                    <div is="vl-property-term" class="vl-properties__label">Telefoon</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <p>
                            <a is="vl-link" href="tel:02 553 80 11" class="vl-link">02 553 80 11<span is="vl-icon" data-vl-icon="phone" data-vl-after="" class="vl-vi-phone vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi" link="" aria-hidiven="true"></span></a>
                        </p>
                    </div>
                    <div is="vl-property-term" class="vl-properties__label">E-mail</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <a is="vl-link" href="mailto:omgeving@vlaanderen.be" class="vl-link">omgeving@vlaanderen.be</a>
                    </div>
                </div>
            </div>
        </div>

        <h2>Handhavingsprocedure</h2>

        <p>
            Heeft u contact opgenomen via omgeving@vlaanderen.be maar bent u niet tevreden met
            het antwoord? Stuur dan uw klacht naar de klachtenbehandelaar van Departement
            Omgeving.
        </p>

        <div id="contact-card-1">
            <div slot="info" data-vl-type="contact">
                <h3 slot="title">Klachtenbehandelaar</h3>
            </div>
            <div slot="properties" class="vl-properties">
                <div is="vl-properties-list" class="vl-properties__list">
                    <div is="vl-property-term" class="vl-properties__label">Adres</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        Koning Albert II-laan 20 bus 8<br></br>1000 Brussel<br></br>België
                    </div>
                    <div is="vl-property-term" class="vl-properties__label">E-mail</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <a is="vl-link" href="mailto:klachten.omgeving@vlaanderen.be" class="vl-link">klachten.omgeving@vlaanderen.be</a>
                    </div>
                </div>
            </div>
        </div>

        <p>Heeft u contact opgenomen met de klachtenbehandelaar van het Departement Omgeving, maar bent u niet tevreden met het antwoord? Stuur dan uw klacht naar de Vlaamse Ombudsdienst.</p>

        <div id="contact-card-3">
            <div slot="info" data-vl-type="contact">
                <h3 slot="title">Vlaamse ombudsdienst</h3>
            </div>
            <div slot="properties" class="vl-properties">
                <div is="vl-properties-list" class="vl-properties__list">
                    <div is="vl-property-term" class="vl-properties__label">Adres</div>
                    <div is="vl-property-value" class="vl-properties__data">Leuvenseweg 86<br></br>1000 Brussel<br></br>België</div>
                    <div is="vl-property-term" class="vl-properties__label">Telefoon</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <p>
                            <a is="vl-link" href="tel:08 002 40 50" class="vl-link">08 002 40 50<span is="vl-icon" data-vl-icon="phone" data-vl-after="" class="vl-vi-phone vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi" link="" aria-hidiven="true"></span></a>
                        </p>
                    </div>
                    <div is="vl-property-term" class="vl-properties__label">E-mail</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <a is="vl-link" href="mailto:klachten@vlaamseombudsdienst.be" class="vl-link">klachten@vlaamseombudsdienst.be<span is="vl-icon" data-vl-icon="mail" data-vl-after="" class="vl-vi-mail vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi" link="" aria-hidiven="true"></span></a>
                    </div>
                    <div is="vl-property-term" class="vl-properties__label">Website</div>
                    <div is="vl-property-value" class="vl-properties__data">
                        <a is="vl-link" href="http://www.vlaamseombudsdienst.be" target="_blank" class="vl-link">http://www.vlaamseombudsdienst.be<span is="vl-icon" data-vl-icon="external" data-vl-after="" class="vl-vi-external vl-link__icon--after vl-link__icon--data-vl-after vl-link__icon vl-vi" link="" aria-hidiven="true"></span></a>
                    </div>
                </div>
            </div>
        </div>

        <Link to="/" className="klimax-button klimax-button--inline">
          <i className="icon-klimax-grid"></i> terug naar Klimax
        </Link>
        </div>
      </div>
    </div>
  );
};

export default Toegankelijkheid;
